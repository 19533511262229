import { ref } from 'vue';
import { defineStore } from 'pinia';
import { getToken } from '@/utils/auth';
import { storage } from '@/utils/storage';

const localUserInfo = storage.get('userInfo', true) || {};
const localIsLogin = getToken() ? true : false;

export const useUserStore = defineStore('user', () => {
  const isLogin = ref(localIsLogin);
  const userInfo = ref(localUserInfo);
  const setUserInfo = (data) => {
    userInfo.value = data;

    storage.set('userInfo', data || {}, true);
  };

  const setIsLogin = (data) => {
    isLogin.value = data;
  };

  return {
    userInfo,
    setUserInfo,
    isLogin,
    setIsLogin,
  };
});
