import router from './router';

import { useUserStore } from '@/stores/user';

router.beforeEach((to) => {
  const { meta } = to;

  const userStore = useUserStore();

  if (meta.title) {
    document.title = meta.title;
  }

  if (meta.needAuth) {
    if (!userStore.isLogin && to.path !== '/login') {
      return {
        path: '/login',
      };
    }
  }
});
