import { setCookie, getCookie, removeCookie } from '@/utils/cookie';

import { TOKEN_KEY } from '@/constants';

export function getToken() {
  return getCookie(TOKEN_KEY);
}

export function setToken(token) {
  return setCookie(TOKEN_KEY, token);
}

export function removeToken() {
  return removeCookie(TOKEN_KEY);
}
