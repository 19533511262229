import { createApp } from 'vue';
import { createPinia } from 'pinia';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

import '@/assets/styles/reset.less';
import '@/assets/styles/commom.less';

import './permission';
import App from './App.vue';
import router from './router';

const pinia = createPinia();
console.log('aaa');
const app = createApp(App);
app.use(pinia);

app.use(router);
app.use(ElementPlus);
app.mount('#pv-platform-app');
