import axios from 'axios';

import { ElMessage } from 'element-plus';

const axiosCofing = {
  headers: {
    Accept: 'application/json;version=3.0;compress=false;',
    'Content-Type': 'application/json;charset=utf-8',
  },
  method: 'get',
  baseURL: '/api/sci-vigi',
  timeout: 30 * 1000,
};

const statusCodes = {
  401: {
    msg: '认证失败，无法访问系统资源',
  },
  403: {
    msg: '当前操作没有权限',
  },
  404: {
    msg: '访问资源不存在',
  },
  405: {
    msg: 'session过期',
  },
  406: {
    msg: '用户已在其他地方登陆!!',
  },
  default: {
    msg: '系统未知错误，请反馈给管理员',
  },
};

export const source = axios.CancelToken.source();

export const http = axios.create(axiosCofing);

http.interceptors.request.use(requestInterceptor);
http.interceptors.response.use(
  responseInterceptorOnSuccess,
  responseInterceptorOnError
);

http.get = (url, data, config) => {
  return http.request({
    url,
    params: data,
    ...config,
  });
};

http.delete = (url, data, config) => {
  return http.request({
    url,
    method: 'delete',
    params: data,
    ...config,
  });
};

function requestInterceptor(config) {
  // console.log('requestInterceptor', config);
  config.cancelToken = source.token;

  if (config.filter) {
    const { data = {} } = config;
    // 过滤假值
    // eslint-disable-next-line no-restricted-syntax
    for (const key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) {
        const item = data[key];
        if (item === null || item === undefined) {
          delete data[key];
        }
      }
    }
  }

  if (config.method === 'get' && config.data) {
    config.params = config.data;
    delete config.data;
  }

  return config;
}
function responseInterceptorOnSuccess(response) {
  const { data } = response;
  // console.log('responseInterceptorOnSuccess', response);

  let message;
  let code;
  let args;
  if (data) {
    try {
      if (
        data instanceof Blob ||
        response.request.responseType === 'blob' ||
        response.request.responseType === 'arraybuffer'
      ) {
        const contentD = response.headers['content-disposition'];

        const realFileName = contentD.split('filename=')[1];
        return {
          success: true,
          data: {
            file: data,
            fileName: realFileName,
          },
        };
      }
    } catch (e) {
      console.error(e);
    }

    if (response.headers['content-type'].indexOf('application/json') < 0) {
      return data;
    }

    if (data.success) {
      return data.data;
    }

    const { errorInfos } = data;
    if (Array.isArray(errorInfos) && errorInfos.length) {
      const firstError = errorInfos[0];
      const errorNotice = response.config.errorNotice || {};
      const notice = {
        ...statusCodes,
        ...errorNotice,
      };
      message = firstError.message;
      code = firstError.code;
      args = firstError.arguments;
      if (code in notice) {
        source.cancel();
        const { msg } = notice[code];

        if (typeof window === 'undefined') {
          if (response.config.message) {
            response.config.message.error(msg, 3, () => {});
          }
          return;
        }

        if (response.config.message) {
          response.config.message.error(msg, 3, () => {
            window.location.href = '/login/';
          });
          setTimeout(() => {
            window.location.href = '/login/';
          }, 3000);
          return;
        }
        window.location.href = '/login/';
        return;
      }
    }
  }

  ElMessage.error({
    message: message,
    duration: 3000,
  });

  return Promise.reject({
    message,
    code,
    arguments: args,
  });
}
function responseInterceptorOnError(error) {
  // console.log('responseInterceptorOnError', error);
  const defaultMessage = '网络错误 稍后再试';

  let errorInfo = {
    message: defaultMessage,
    arguments: undefined,
    code: null,
  };
  if (error) {
    if (error.response && error.response.data) {
      const {
        response: {
          data: { errors = [] },
        },
      } = error;
      const errorObj = (errors && errors[0]) || {};

      errorInfo = {
        message: errorObj.message || error.message || defaultMessage,
        arguments: errorObj.arguments || undefined,
        code: errorObj.code || null,
      };
    } else {
      errorInfo = {
        message: error.message || defaultMessage,
        arguments: undefined,
        code: null,
      };
    }
  }

  ElMessage.error({
    message: errorInfo.message,
    duration: 3000,
  });
  return Promise.reject(errorInfo);
}
